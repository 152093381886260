import { createSlice } from "@reduxjs/toolkit";

const stateSlice = createSlice({
  name: "inquiry/state",
  initialState: {
    deleteConfirmation: false,
    selectedInquiry: "",
    newDialog: false,
  },
  reducers: {
    setSelectedInquiry: (state, action) => {
      state.selectedInquiry = action.payload;
    },
    toggleNewDialog: (state, action) => {
      state.newDialog = action.payload;
    },
  },
});

export const { toggleDeleteConfirmation, setSelectedInquiry, toggleNewDialog } =
  stateSlice.actions;

export default stateSlice.reducer;
