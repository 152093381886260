import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { getPrice } from "../store/dataSlice";
import { setSearchFilter } from "../store/stateSlice";
// import { getTask } from "../store/dataSlice";

const PriceSearch = () => {
  const dispatch = useDispatch();
  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.cranePrice.data.tableData
  );
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      await dispatch(
        getPrice({
          data: {
            searchQuery: searchQuery,
            pageIndex,
            pageSize,
          },
          headers: {
            connection: "cranebuffer",
          },
        })
      );

      dispatch(setSearchFilter(searchQuery));
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };

  return (
    <div className="flex items-center">
      <input
        type="text"
        placeholder="Search"
        value={searchQuery}
        onChange={handleSearchChange}
        className="px-2 py-2 border focus:outline-none focus:border-blue-500 rounded-s"
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            handleSubmit();
          }
        }}
      />
      <div
        className="bg-[#096CAE] text-white mr-4 px-2.5 py-[11.5px] rounded-e cursor-pointer"
        onClick={handleSubmit}
      >
        <FaSearch className="text-white " />
      </div>
    </div>
  );
};

export default PriceSearch;
