import React, { useCallback, useEffect } from "react";
import { Button, Spin, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getInquiryDetails, setTableData } from "../store/dataSlice";
import { TiArrowBack } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
const InquiryDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedInquiry = useSelector(
    (state) => state.shockInquiry.state.selectedInquiry
  );

  const loading = useSelector((state) => state.shockInquiry.data.loading);

  const rowClassName = () => {
    return "!h-auto !py-2";
  };
  const inquiry = {
    _id: { $oid: "663366047d48ca6956546089" },
    username: "user",
    project: "dsadasda",
    email: "user@gmail.com",
    GSTn: "adasdad",
    phone: "9890852663",
    company: "",
    shockAbsorber: 2,
    model: "ACX3350-4",
    price: 450,
    spare: [
      {
        name: "Front Flange",
        price: 450,
        _id: { $oid: "663366047d48ca695654608a" },
      },
    ],
    series: "ACX",
    currency: "INR",
    AdditionalAccessories: [],
    drawing: true,
    drawingFormat: ["DXF"],
    address:
      "Unnamed road, Satara District, Satara - 415002, Maharashtra, India",
    temperature: "",
    __v: 0,
  };

  const columns = [
    {
      title: "Sl. No.",
      key: "index",
      width: 70,
      align: "center",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: 120,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
      width: 120,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 180,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "GST Number",
      dataIndex: "GSTn",
      key: "GSTn",
      width: 140,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: 140,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      width: 120,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Shock Absorber",
      dataIndex: "shockAbsorber",
      key: "shockAbsorber",
      width: 140,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      width: 140,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 100,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Spare Parts",
      dataIndex: "spare",
      key: "spare",
      width: 200,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">
              {text.map((item) => item.name).join(", ")}
            </span>
          </div>
        );
      },
    },
    {
      title: "Series",
      dataIndex: "series",
      key: "series",
      width: 100,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      width: 100,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Drawing",
      dataIndex: "drawing",
      key: "drawing",
      width: 100,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">
              {text ? "Yes" : "No"}
            </span>
          </div>
        );
      },
    },
    {
      title: "Drawing Format",
      dataIndex: "drawingFormat",
      key: "drawingFormat",
      width: 150,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">
              {text.join(", ")}
            </span>
          </div>
        );
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 300,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Temperature",
      dataIndex: "temperature",
      key: "temperature",
      width: 120,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
  ];

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.shockInquiry.data.tableData
  );
  const data = useSelector(
    (state) => state.shockInquiry.data.inquiryDetailsList?.data
  );

  const fetchData = useCallback(() => {
    dispatch(
      getInquiryDetails({
        data: {
          pageIndex,
          pageSize,
          email: selectedInquiry.email,
        },
        headers: {
          connection: "shockabsorber",
        },
      })
    );
  }, [pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize]);

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  return (
    <div className="bg-white m-4 p-8 rounded-xl">
      <Button
        className="flex bg-[#096CAE] text-white mb-4 float-right"
        onClick={() => navigate("/shockabsorber/inquiry")}
      >
        <span>
          <TiArrowBack className="text-lg" />
        </span>
        <p>Go Back</p>
      </Button>
      <div>
        {loading ? (
          <div className="flex justify-center items-center h-screen">
            <Spin size="large" />
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            bordered
            scroll={{
              x: 1400,
            }}
            pagination={{
              current: pageIndex,
              pageSize: pageSize,
              total: total,
              showSizeChanger: true,
              pageSizeOptions: [5, 10, 20],
              onShowSizeChange: handleTableChange,
            }}
            tableLayout="fixed"
            onChange={handleTableChange}
            rowClassName={rowClassName}
          />
        )}
      </div>
    </div>
  );
};

export default InquiryDetail;
