import React, { useCallback, useEffect, useState } from "react";
import { Layout, Table, Button, Modal, Tooltip, Spin } from "antd";
import {
  InfoCircleOutlined,
  MailOutlined,
  PhoneOutlined,
  ProjectOutlined,
  UserOutlined,
} from "@ant-design/icons";
import InquiryDetail from "./InquiryDetails";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedInquiry } from "../store/stateSlice";
import { getInquiry, setTableData } from "../store/dataSlice";
import { useNavigate } from "react-router-dom";

const Inquiry = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.craneInquiry.data.loading);

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.craneInquiry.data.tableData
  );
  const data = useSelector(
    (state) => state.craneInquiry.data.inquiryList?.data
  );

  const fetchData = useCallback(() => {
    dispatch(
      getInquiry({
        data: {
          pageIndex,
          pageSize,
        },
        headers: {
          connection: "cranebuffer",
        },
      })
    );
  }, [pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize]);

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  const showDetails = (record) => {
    dispatch(setSelectedInquiry(record));
    navigate("/cranebuffer/inquiry/detail");
  };

  const handleClose = () => {
    dispatch(setSelectedInquiry(null));
  };

  const rowClassName = () => {
    return "!h-auto !py-2";
  };

  // Define table columns with tooltips and icons
  const columns = [
    {
      title: (
        <Tooltip title="Username">
          <UserOutlined /> Username
        </Tooltip>
      ),
      dataIndex: "username",
      key: "username",
      align: "center",
      render: (text) => (text ? text : "---"),
    },
    {
      title: (
        <Tooltip title="Email">
          <MailOutlined /> Email
        </Tooltip>
      ),
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (text) => (text ? text : "---"),
    },
    {
      title: (
        <Tooltip title="Project">
          <ProjectOutlined /> Project
        </Tooltip>
      ),
      dataIndex: "project",
      key: "project",
      align: "center",
      render: (text) => (text ? text : "---"),
    },
    {
      title: (
        <Tooltip title="Phone">
          <PhoneOutlined /> Phone
        </Tooltip>
      ),
      dataIndex: "phone",
      key: "phone",
      align: "center",
      render: (text) => (text ? text : "---"),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => showDetails(record)}
          icon={<InfoCircleOutlined />}
          className="!text-xs"
        >
          View Details
        </Button>
      ),
    },
  ];

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          bordered
          tableLayout="fixed"
          pagination={{
            current: pageIndex,
            pageSize: pageSize,
            total: total,
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20],
            onShowSizeChange: handleTableChange,
          }}
          onChange={handleTableChange}
          rowClassName={rowClassName}
        />
      )}
    </div>
  );
};

export default Inquiry;
