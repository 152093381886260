import React, { useState, useRef, useEffect } from "react";
import { Button, message } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";

const CustomUpload = ({ fileType, setFieldValue, name, resetTrigger }) => {
  const [fileList, setFileList] = useState([]);

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (resetTrigger) {
      setFileList([]);
      setFieldValue(name, []);

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  }, [resetTrigger, name, setFieldValue]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    const validFiles = [];

    files.forEach((file) => {
      if (!file) {
        return;
      }

      const fileTypes = (file.type || file.name.split(".").pop()).toLowerCase();


      if (fileTypes !== fileType) {
        message.error(`Only files of type ${fileType} are allowed`);
      } else {
        validFiles.push(file);
      }
    });

    const updatedFileList = [...fileList, ...validFiles];

    setFileList(updatedFileList);
    setFieldValue(name, updatedFileList);
  };

  const handleRemove = (fileToRemove) => {
    const updatedFileList = fileList.filter((file) => file !== fileToRemove);
    setFileList(updatedFileList);
    setFieldValue(name, updatedFileList);

    const fileInput = document.querySelector(`input[name="${name}"]`);
    if (fileInput) {
      fileInput.value = "";
    }
  };

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
        accept={fileType}
        multiple
        name={name} // Ensure the name is consistent with Formik
        onChange={handleFileChange}
      />

      <Button
        icon={<UploadOutlined />}
        className="w-full md:w-42 h-10"
        onClick={() => fileInputRef.current.click()}
      >
        Upload {name}
      </Button>

      {/* Display selected files */}
      {fileList.length > 0 && (
        <div style={{ marginTop: 10 }}>
          <ul>
            {fileList.map((file) => (
              <li
                key={file.uid}
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                {file.name}{" "}
                <Button
                  type="text"
                  danger
                  size="small"
                  icon={<DeleteOutlined />}
                  onClick={() => handleRemove(file)}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomUpload;
