import React, { useCallback, useEffect } from "react";
import { Spin, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getPrice, setTableData } from "../store/dataSlice";
import {
  setSelectedPrices,
  toggleNewDialog,
  toggleUploadDialog,
} from "../store/stateSlice";
import { FaRegEdit } from "react-icons/fa";
import { TbCloudUpload } from "react-icons/tb";
const PriceTable = () => {
  const dispatch = useDispatch();

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.shockPrice.data.tableData
  );
  const data = useSelector((state) => state.shockPrice.data.priceList?.data);
  const loading = useSelector((state) => state.shockPrice.data.loading);

  const filter = useSelector((state) => state.shockPrice.state.newFilter);
  const seriesFilter = useSelector(
    (state) => state.shockPrice.state.seriesFilters
  );

  const fetchData = useCallback(() => {
    dispatch(
      getPrice({
        data: {
          pageIndex,
          pageSize,
          ...(filter && { seriesFilter }),
        },
        headers: {
          connection: "shockabsorber",
        },
      })
    );
  }, [pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize]);

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  const rowClassName = () => {
    return "!h-auto !py-2";
  };

  const onEdit = (record) => {
    dispatch(setSelectedPrices(record));
    dispatch(toggleNewDialog(true));
  };

  const onUpload = (record) => {
    dispatch(setSelectedPrices(record));
    dispatch(toggleUploadDialog(true));
  };
  const columns = [
    {
      title: <span className="text-gray-500">Action</span>,
      dataIndex: "action",
      fixed: "left",
      width: 100,
      align: "center",
      render: (_, record) => (
        <>
          <div className="flex  gap-4 justify-center items-center">
            <span
              onClick={() => onEdit(record)}
              className="text-xl text-[#096CAE] cursor-pointer"
            >
              <FaRegEdit />
            </span>
            <span
              onClick={() => onUpload(record)}
              className="text-2xl text-[#096CAE] cursor-pointer"
            >
              <TbCloudUpload />
            </span>
          </div>
        </>
      ),
    },
    {
      title: "Series",
      dataIndex: "series",
      key: "series",
      width: "60px",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Serial No",
      dataIndex: "SERIAL NO",
      key: "serial_no",
      width: "80px",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Model",
      dataIndex: "Model",
      key: "model",
      width: "120px",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Stroke",
      dataIndex: "Stroke",
      key: "stroke",
      width: "80px",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Threading",
      dataIndex: "THREADING",
      key: "threading",
      width: "120px",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Nm per Stroke",
      dataIndex: "nmperstroke",
      key: "nm_per_stroke",
      width: "120px",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Nm per Hr",
      dataIndex: "nmperhr",
      key: "nm_per_hr",
      width: "100px",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "EMASS Min",
      dataIndex: "EMASSMIN",
      key: "emass_min",
      width: "100px",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "EMASS Max",
      dataIndex: "EMASSMAX",
      key: "emass_max",
      width: "100px",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "SF Min",
      dataIndex: "SFMIN",
      key: "sf_min",
      width: "100px",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "SF Max",
      dataIndex: "SFMAX",
      key: "sf_max",
      width: "100px",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Weight (gms)",
      dataIndex: "WEIGHT IN GMS",
      key: "weight_in_gms",
      width: "120px",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "New Price",
      dataIndex: "NEWPRICE",
      key: "new_price",
      width: "100px",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Front Flange",
      dataIndex: "Front Flange",
      key: "front_flange",
      width: "120px",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Clevis Mount",
      dataIndex: "Clevis Mount",
      key: "clevis_mount",
      width: "120px",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Side Load Adapter",
      dataIndex: "Side Load Adapter",
      key: "side_load_adapter",
      width: "140px",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Stop Coller",
      dataIndex: "Stop Coller",
      key: "stop_coller",
      width: "100px",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Rate per Nm",
      dataIndex: "RATE PER NM",
      key: "rate_per_nm",
      width: "120px",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "No Cap",
      dataIndex: "NO CAP",
      key: "no_cap",
      width: "100px",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Metallic Cap",
      dataIndex: "METTALIC CAP",
      key: "metallic_cap",
      width: "100px",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Metallic Cap with PU Button",
      dataIndex: "METALLIC CAP WITH PU BUTTON",
      key: "metallic_cap_with_pu_button",
      width: "200px",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Lock Nut Extra",
      dataIndex: "LOCK NUT EXTRA",
      key: "lock_nut_extra",
      width: "120px",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          bordered
          scroll={{
            x: 1400,
          }}
          tableLayout="fixed"
          pagination={{
            current: pageIndex,
            pageSize: pageSize,
            total: total,
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20],
            onShowSizeChange: handleTableChange,
          }}
          onChange={handleTableChange}
          rowClassName={rowClassName}
        />
      )}
    </div>
  );
};

export default PriceTable;
