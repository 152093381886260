import ApiService from "./ApiService";

export async function apiCatalogCreate(data, headers) {
  return ApiService.fetchData({
    url: "/api/catalog/create",
    method: "post",
    data,
    headers: {
      "x-db-connection": headers.connection,
    },
  });
}

export async function apiCatalogGet(data, headers) {
  return ApiService.fetchData({
    url: "/api/catalog/get",
    method: "post",
    data,
    headers: {
      "x-db-connection": headers.connection,
    },
  });
}

export async function apiCatalogUpdate(data, headers) {
  return ApiService.fetchData({
    url: "/api/catalog/update",
    method: "put",
    data,
    headers: {
      "x-db-connection": headers.connection,
    },
  });
}

export async function apiCatalogDelete(data, headers) {
  return ApiService.fetchData({
    url: "/api/catalog/delete",
    method: "delete",
    data,
    headers: {
      "x-db-connection": headers.connection,
    },
  });
}
