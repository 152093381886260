import ApiService from "./ApiService";

export async function apiGetAllInquiry(data, headers) {
  return ApiService.fetchData({
    url: "/api/inquiry/get",
    method: "post",
    data,
    headers: {
      "x-db-connection": headers.connection,
    },
  });
}

export async function apiGetAllInquiryDetails(data, headers) {
  return ApiService.fetchData({
    url: "/api/inquiryDetails/get",
    method: "post",
    data,
    headers: {
      "x-db-connection": headers.connection,
    },
  });
}
