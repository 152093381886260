import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  apiGetAllPrice,
  apiPostData,
  apiPutData,
} from "../../../../services/PriceService.js";

export const getPrice = createAsyncThunk(
  "price/data/getPrice",
  async ({ data, headers }) => {
    const response = await apiGetAllPrice(data, headers);
    return response.data;
  }
);

export const putData = createAsyncThunk(
  "price/data/putData",
  async ({ data, headers }) => {
    const response = await apiPutData(data, headers);
    return response.data;
  }
);

export const createData = createAsyncThunk(
  "price/data/createData",
  async ({ data, headers }) => {
    const response = await apiPostData(data, headers);
    return response.data;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  productStatus: 0,
};

const dataSlice = createSlice({
  name: "priceList/data",
  initialState: {
    loading: false,
    priceList: [],
    tableData: initialTableData,
    filterData: initialFilterData,
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPrice.fulfilled, (state, action) => {
        state.loading = false;
        state.priceList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getPrice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPrice.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { setTableData, setFilterData } = dataSlice.actions;

export default dataSlice.reducer;
