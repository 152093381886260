import React, { useEffect, useState } from "react";
import {
  Avatar,
  Flex,
  Typography,
  Dropdown,
  Menu,
  Modal,
  notification,
  Tooltip,
  Input,
  Form,
  Space,
} from "antd";
import { HiOutlineLogout, HiOutlineSwitchHorizontal } from "react-icons/hi";
import {
  MdOutlineChangeCircle,
  MdOutlineNotifications,
  MdOutlineSettings,
} from "react-icons/md";
import styled from "styled-components";
// import admin from "../../assets/images/role/admin.png";
// import user from "../../assets/images/user/leader.jpg";
// import employee from "../../assets/images/role/employee.png";
// import leader from "../../assets/images/role/leader.png";
import logout from "../../view/login/Logout";
// import ChangePassword from "../../view/login/ChangePassword";
import { useDispatch } from "react-redux";
import { getMenu } from "../Sidebar/store/dataSlice";
import { useNavigate } from "react-router-dom";
import { GiCycle } from "react-icons/gi";

export const CustomHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [isChangePasswordModalVisible, setIsChangePasswordModalVisible] =
  //   useState(false);

  // const userData = localStorage.getItem("UserData");
  // const roleDetails = JSON.parse(userData);
  // const userData2 = JSON.parse(localStorage.getItem("UserData"));
  // const EMP_ID = userData2[0].EMP_ID;
  // const EMP_NAME = userData2[0].NAME;

  // const showChangePasswordModal = () => {
  //   setIsChangePasswordModalVisible(true);
  // };

  // const handleCancelChangePasswordModal = () => {
  //   setIsChangePasswordModalVisible(false);
  // };
  const menu = (
    <StyledMenu>
      {/* <Menu.Item
        key="2"
        icon={<TbPasswordMobilePhone style={{ fontSize: "18px" }} />}
        onClick={showChangePasswordModal}
      >
        Change Password
      </Menu.Item> */}

      <Menu.Item
        key="1"
        icon={<HiOutlineLogout style={{ fontSize: "18px" }} />}
        onClick={logout}
      >
        Logout
      </Menu.Item>
    </StyledMenu>
  );

  useEffect(() => {
    // const savedRoleId = localStorage.getItem("ROLE_ID");
    // const defaultRole = roleDetails[0].ROLE_DETAILS.find(
    //   (role) => role.ROLE_ID === (savedRoleId ? Number(savedRoleId) : 1)
    // );
    // if (defaultRole) {
    // dispatch(getMenu({ ROLE_ID: defaultRole.ROLE_ID, EMP_ID }));
    // }
  }, [dispatch]);

  useEffect(() => {
    // const modalShown = localStorage.getItem("modalShown");
    // if (!modalShown && roleDetails && roleDetails[0].ROLE_DETAILS.length > 1) {
    //   setIsModalVisible(true);
    //   localStorage.setItem("modalShown", "true");
    // }
  }, []);

  const handleClick = () => {
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleRoleSelect = async (roleId, roleName) => {
    localStorage.setItem("ROLE_ID", roleId);
    try {
      const menuResponse = await dispatch().unwrap();
      // getMenu({ ROLE_ID: roleId, EMP_ID })
      const menuData = menuResponse.data;
      if (menuData && menuData.length > 0) {
        const dashboardItem = menuData.find(
          (item) => item.link === "/dashboard"
        );
        const targetItem =
          dashboardItem ||
          menuData.find(
            (item) =>
              item.SEQ_NO === 0 ||
              item.SEQ_NO === 1 ||
              item.SEQ_NO === 2 ||
              item.SEQ_NO === 3 ||
              item.SEQ_NO === 4
          );

        if (targetItem) {
          if (targetItem.link === "#") {
            const firstChildLink =
              targetItem.children && targetItem.children.length > 0
                ? targetItem.children[0].link
                : null;
            if (firstChildLink) {
              navigate(firstChildLink);
            } else {
              showAccessDeniedModal();
            }
          } else {
            navigate(targetItem.link);
          }
        } else {
          showAccessDeniedModal();
        }
      } else {
        showAccessDeniedModal();
      }
      api.success({
        message: `Successfully updated to ${roleName}`,
        duration: 1,
      });
    } catch (error) {
      api.error({
        message: "Error",
        description: "Failed to update the menu based on the selected role.",
      });
    }

    setIsModalVisible(false);
  };

  const showAccessDeniedModal = () => {
    Modal.error({
      title: "Access Denied",
      content: "You do not have access to this role.",
      onOk: () => {
        // localStorage.removeItem("UserData");
        // localStorage.removeItem("ROLE_ID");
        navigate("/login");
      },
    });
  };

  // const roleImages = {
  //   Admin: admin,
  //   Employee: employee,
  //   Leader: leader,
  //   "HR Manager": leader,
  // };

  const menu2 = (
    <StyledMenu className="w-[280px] mt-[20px]">
      <div className="flex flex-col mb-4">
        <div className="font-bold text-base mb-1 flex">
          <p>{getGreeting()},</p>{" "}
          <p className="text-base flex items-center font-medium ml-1">
            {/* {EMP_NAME} */}
          </p>
        </div>

        <Typography.Text className="text-green-500">
          {/* {
            roleDetails[0].ROLE_DETAILS.find(
              (role) => role.ROLE_ID === Number(localStorage.getItem("ROLE_ID"))
            )?.ROLE_NAME
          } */}
        </Typography.Text>
      </div>

      <hr />

      <Menu.Item
        key="1"
        icon={<HiOutlineLogout style={{ fontSize: "22px" }} />}
        className="!text-[16px] !mt-4"
        onClick={logout}
      >
        Logout
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<HiOutlineSwitchHorizontal style={{ fontSize: "22px" }} />}
        onClick={handleClick}
        className="!text-[16px] !mb-2"
      >
        Change Role
      </Menu.Item>
    </StyledMenu>
  );

  function getGreeting() {
    const currentTime = new Date().getHours();
    if (currentTime < 12) {
      return "Good Morning";
    } else if (currentTime < 17) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  }

  return (
    <Flex align="center" justify="end">
      {contextHolder}
      <Flex
        align="center"
        justify="end"
        className="hidden md:flex p-4 text-gray-600"
      >
        {/* <Tooltip title="Click to choose roles">
          <div
            className="rounded-full w-10 mr-4 cursor-pointer"
            onClick={handleClick}
          >
            <MdOutlineChangeCircle className="text-3xl" />
          </div>
        </Tooltip> */}

        {/* <Tooltip title="Notifications">
          <MdOutlineNotifications
            className="text-3xl mr-4 cursor-pointer"
            // onClick={handleNotificationsClick}
          />
        </Tooltip> */}

        {/* <Tooltip title="Settings">
          <MdOutlineSettings
            className="text-3xl mr-4 cursor-pointer"
            // onClick={handleSettingsClick}
          />
        </Tooltip> */}

        <Tooltip title="Logout">
          <HiOutlineLogout
            className="text-3xl cursor-pointer"
            onClick={logout}
          />
        </Tooltip>
        <StyledModal
          title={<StyledModalTitle>Select Your Role</StyledModalTitle>}
          open={isModalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          footer={null}
        >
          <ModalContent>
            {/* {roleDetails[0].ROLE_DETAILS.map(({ ROLE_ID, ROLE_NAME }) => (
              <RoleContainer
                key={ROLE_ID}
                onClick={() => handleRoleSelect(ROLE_ID, ROLE_NAME)}
              >
                <img
                  src={roleImages[ROLE_NAME] || admin}
                  alt={ROLE_NAME}
                  className="role-image"
                />
                <RoleLabel>{ROLE_NAME}</RoleLabel>
              </RoleContainer>
            ))} */}
          </ModalContent>
        </StyledModal>
      </Flex>

      {/* <Modal
        title="Change Password"
        visible={isChangePasswordModalVisible}
        onCancel={handleCancelChangePasswordModal}
        footer={null}
      >
        <ChangePassword onCancel={handleCancelChangePasswordModal} />
      </Modal> */}
    </Flex>
  );
};

const StyledAvatarContainer = styled.div`
  width: 45px;
  height: 45px;
  background-color: #f0f0f0;
  border: 2px solid #1890ff;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  margin-left: 20px;

  .avatar {
    width: 100%;
    height: 100%;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-title {
    text-align: center;
    width: 100%;
  }
`;

const StyledModalTitle = styled.div`
  width: 100%;
  text-align: center;
`;

const ModalContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const RoleContainer = styled.div`
  text-align: center;
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border: 2px solid #eee;
  border-radius: 8px;
  padding: 20px;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
    border-color: #1890ff;
  }

  .role-image {
    width: 100px;
    height: 100px;
    cursor: pointer;
    margin-bottom: 10px;
  }
`;

const RoleLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const StyledCard = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  width: 250px;
  margin: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const StyledDropdown = styled(Dropdown)`
  margin-top: 10px;
`;

const StyledMenu = styled(Menu)`
  border-radius: 8px;
  margin-top: 6px !important;
  padding: 10px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;
