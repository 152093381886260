import { apiLogout } from "../../services/AuthService";
import { persistor } from "../../store/index";
const logout = async () => {
  try {
    localStorage.removeItem("token");
    localStorage.clear();
    persistor.flush();
    persistor.purge();

    persistor.pause();

    window.location.reload();
    // }

    // Redirect to the login page
  } catch (error) {
    console.error("Logout failed", error);
  }
};

export default logout;
