import React, { useEffect, useState } from "react";
import InquiryReducer from "./store";
import { injectReducer } from "../../../store";
import { useSelector, useDispatch } from "react-redux";
import { Button, Drawer } from "antd";
import { LuPlus } from "react-icons/lu";
import { setSelectedInquiry, toggleNewDialog } from "./store/stateSlice";
import { CloseOutlined } from "@mui/icons-material";
// import PriceSearch from "./components/PriceSearch";
import Inquiry from "./components/Inquiry";

injectReducer("craneInquiry", InquiryReducer);
const CraneBufferInquiry = () => {
  const dispatch = useDispatch();
  const [drawerVisible, setDrawerVisible] = useState(false);
  // const dialog = useSelector((state) => state.price.state.newDialog);

  const onDialog = () => {
    dispatch(setSelectedInquiry(null));
    dispatch(toggleNewDialog(true));
  };

  const onClose = () => {
    dispatch(toggleNewDialog(false));
  };

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">Inquiries</div>
          <div className="flex">
            <div className="flex items-center">
              <div>{/* <PriceSearch /> */}</div>
              {/* <div>
                <Button
                  style={{
                    backgroundColor: "#096CAE",
                    color: "#ffff",
                    display: "flex",
                    padding: "18px",
                    borderRadius: "6px",
                  }}
                  onClick={onDialog}
                >
                  <LuPlus />
                  <p>Create</p>
                </Button>
              </div> */}
            </div>
          </div>
        </div>
        {/* <div className="filter mb-4">
          <StatusFilter />
        </div> */}

        <Inquiry />
      </div>
    </>
  );
};

export default CraneBufferInquiry;
