import React, { useEffect, useState } from "react";
import { Menu, Flex } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import * as IoIcons from "react-icons/io";
import * as BsIcons from "react-icons/bs";
import * as GrIcons from "react-icons/gr";
import * as FaIcons from "react-icons/fa";
import * as GiIcons from "react-icons/gi";
import * as RiIcons from "react-icons/ri";
import * as MdIcons from "react-icons/md";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo/adoniTech-1.png";

// Static menu data
const staticMenuData = [
  // {
  //   title: "Dashboard",
  //   link: "/dashboard",
  //   icon: "MdDashboardCustomize",
  // },
  {
    title: "ShockAbsorber",
    link: "#",
    icon: "GiSpring",
    children: [
      {
        title: "Prices",
        link: "/shockabsorber/prices",
        icon: "MdPriceChange",
      },
      {
        title: "Inquiry",
        link: "/shockabsorber/inquiry",
        icon: "RiSearchLine",
      },
      {
        title: "Catalog",
        link: "/shockabsorber/catalog",
        icon: "GrCatalog",
      },
    ],
  },
  {
    title: "CraneBuffer",
    link: "#",
    icon: "MdOutlineConstruction",
    children: [
      {
        title: "Prices",
        link: "/cranebuffer/prices",
        icon: "MdPriceChange",
      },
      {
        title: "Inquiry",
        link: "/cranebuffer/inquiry",
        icon: "RiSearchLine",
      },
      {
        title: "Catalog",
        link: "/cranebuffer/catalog",
        icon: "GrCatalog",
      },
    ],
  },
];

// Combine all icons
const Icons = {
  ...IoIcons,
  ...BsIcons,
  ...GrIcons,
  ...FaIcons,
  ...RiIcons,
  ...GiIcons,
  ...MdIcons,
};

export const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(location.pathname);
  const [openKeys, setOpenKeys] = useState([]);

  useEffect(() => {
    setSelectedKey(location.pathname);
  }, [location.pathname]);

  const generateMenuItems = (data) => {
    const sortedData = data.sort((a, b) => a.SEQ_NO - b.SEQ_NO);

    return sortedData.map((item) => {
      let IconComponent = Icons[item.icon];

      if (item.children && item.children.length > 0) {
        return {
          key: item.title,
          label: item.title,
          icon: IconComponent ? (
            <IconComponent
              className={`menu-icon ${
                selectedKey === item.link ? "selected" : ""
              }`}
            />
          ) : null,
          children: generateMenuItems(item.children),
        };
      } else {
        return {
          key: item.link,
          label: item.title,
          icon: IconComponent ? (
            <IconComponent
              className={`menu-icon ${
                selectedKey === item.link ? "selected" : ""
              }`}
            />
          ) : null,
        };
      }
    });
  };

  const handleOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));
    if (openKeys.includes(latestOpenKey)) {
      setOpenKeys(openKeys.filter((key) => key !== latestOpenKey));
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <>
      <Flex align="center" justify="center">
        <div className="logo">
          <Link to='/'>
          <img src={logo} width={160} alt="Adonitech" />
          </Link>
      
        </div>
      </Flex>
      <Menu
        mode="inline"
        className="menu-bar !md:text-base !text-sm"
        selectedKeys={[selectedKey]}
        openKeys={openKeys}
        onClick={(items) => {
          setSelectedKey(items.key);
          navigate(items.key);
        }}
        onOpenChange={handleOpenChange}
        items={generateMenuItems(staticMenuData)}
      />
    </>
  );
};
