import React, { useState } from "react";
import { Modal, Table, Button, Tooltip, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toggleUploadDialog } from "../store/stateSlice";
import { FaFileDownload } from "react-icons/fa";
import CustomUpload from "./Upload";
import { createShockData, getPrice } from "../store/dataSlice";

const UploadFilesDialog = () => {
  const dispatch = useDispatch();
  const dialog = useSelector((state) => state.shockPrice.state.uploadDialog);
  const selectedPrices = useSelector(
    (state) => state.shockPrice.state.selectedPrices
  );
  const [api, contextHolder] = notification.useNotification();
  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.shockPrice.data.tableData
  );
  const [resetTrigger, setResetTrigger] = useState(false);
  const [rowData, setRowData] = useState({
    1: { pdf: [], dxf: [], iges: [], step: [] },
    2: { pdf: [], dxf: [], iges: [], step: [] },
    3: { pdf: [], dxf: [], iges: [], step: [] },
    4: { pdf: [], dxf: [], iges: [], step: [] },
    5: { pdf: [], dxf: [], iges: [], step: [] },
  });

  const onCancel = () => {
    setResetTrigger((prev) => !prev);
    dispatch(toggleUploadDialog(false));
  };

  const handleFileUpload = (rowKey, fileType, files) => {
    setRowData((prev) => ({
      ...prev,
      [rowKey]: {
        ...prev[rowKey],
        [fileType]: files,
      },
    }));
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    Object.keys(rowData).forEach((rowKey) => {
      const rowFiles = rowData[rowKey];

      // Set the prefix based on the rowKey
      let prefix = "";
      switch (rowKey) {
        case "1":
          prefix = "ff"; // Front Flange
          break;
        case "2":
          prefix = "cm"; // Clevis Mount
          break;
        case "3":
          prefix = "sla"; // Side Load Adapter
          break;
        case "4":
          prefix = "sc"; // Stop Caller
          break;
        case "5":
          prefix = "ot"; // Stop Caller
          break;
        default:
          break;
      }

      Object.keys(rowFiles).forEach((fileType) => {
        rowFiles[fileType].forEach((file) => {
          const key = `${prefix}_${fileType}`;
          formData.append(key, file);
        });
      });
    });

    formData.append("id", selectedPrices?._id);

   

    try {
      const action = await dispatch(
        createShockData({
          data: formData,
          headers: {
            connection: "shockabsorber",
          },
        })
      );

      if (action.payload.code === 200) {
        onCancel();
        dispatch(
          getPrice({
            data: {
              pageIndex,
              pageSize,
            },
            headers: {
              connection: "shockabsorber",
            },
          })
        );

        // Reset the rowData state after successful submission
        setRowData({
          1: { pdf: [], dxf: [], iges: [], step: [] },
          2: { pdf: [], dxf: [], iges: [], step: [] },
          3: { pdf: [], dxf: [], iges: [], step: [] },
          4: { pdf: [], dxf: [], iges: [], step: [] },
          5: { pdf: [], dxf: [], iges: [], step: [] },
        });

        // Notify user of success
        api.success({
          message: "Form Submitted Successfully.",
        });
      } else {
        console.error(
          "Error occurred during form submission:",
          action.payload.error
        );
      }
      return action.payload.code;
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "PDF",
      key: "pdf",
      render: (_, record) => (
        <CustomUpload
          fileType="application/pdf"
          name="PDF"
          resetTrigger={resetTrigger}
          setFieldValue={(name, files) =>
            handleFileUpload(record.key, "pdf", files)
          }
        />
      ),
    },
    {
      title: "DXF",
      key: "dxf",
      render: (_, record) => (
        <CustomUpload
          fileType="dxf"
          name="DXF"
          resetTrigger={resetTrigger}
          setFieldValue={(name, files) =>
            handleFileUpload(record.key, "dxf", files)
          }
        />
      ),
    },
    {
      title: "IGES",
      key: "iges",
      render: (_, record) => (
        <CustomUpload
          fileType="igs"
          name="IGS"
          resetTrigger={resetTrigger}
          setFieldValue={(name, files) =>
            handleFileUpload(record.key, "igs", files)
          }
        />
      ),
    },
    {
      title: "STEP",
      key: "step",
      render: (_, record) => (
        <CustomUpload
          fileType="step"
          name="STEP"
          resetTrigger={resetTrigger}
          setFieldValue={(name, files) =>
            handleFileUpload(record.key, "step", files)
          }
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        const rowFiles = rowData[record.key];
        return (
          <div style={{ display: "flex", gap: "10px" }}>
            {rowFiles.pdf.length > 0 && (
              <Tooltip title="PDF Download">
                <Button
                  type="link"
                  icon={
                    <FaFileDownload
                      style={{ color: "red", fontSize: "18px" }}
                    />
                  }
                  onClick={() => {
                    const file = rowFiles.pdf[0];
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL, "_blank");
                  }}
                />
              </Tooltip>
            )}
            {rowFiles.dxf.length > 0 && (
              <Tooltip title="DXF Download">
                <Button
                  type="link"
                  icon={
                    <FaFileDownload
                      style={{ color: "green", fontSize: "18px" }}
                    />
                  }
                  onClick={() => {
                    const file = rowFiles.dxf[0];
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL, "_blank");
                  }}
                />
              </Tooltip>
            )}
            {rowFiles.iges.length > 0 && (
              <Tooltip title="IGS Download">
                <Button
                  type="link"
                  icon={
                    <FaFileDownload
                      style={{ color: "blue", fontSize: "18px" }}
                    />
                  }
                  onClick={() => {
                    const file = rowFiles.iges[0];
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL, "_blank");
                  }}
                />
              </Tooltip>
            )}
            {rowFiles.step.length > 0 && (
              <Tooltip title="STEP Download">
                <Button
                  type="link"
                  icon={
                    <FaFileDownload
                      style={{ color: "orange", fontSize: "18px" }}
                    />
                  }
                  onClick={() => {
                    const file = rowFiles.step[0];
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL, "_blank");
                  }}
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  const data = [
    { key: "1", name: "Front Flange" },
    { key: "2", name: "Clevis Mount" },
    { key: "3", name: "Side Load Adapter" },
    { key: "4", name: "Stop Caller" },
    { key: "5", name: "Others" }, // Added new row for Others
  ];

  return (
    <Modal
      title={
        <span style={{ fontSize: "24px", fontWeight: "500" }}>
          Upload Attachments
        </span>
      }
      visible={dialog}
      onCancel={onCancel}
      footer={[
        <Button key="close" onClick={onCancel}>
          Close
        </Button>,
        <Button key="done" type="primary" onClick={handleSubmit}>
          Done
        </Button>,
      ]}
      width={1200}
    >
      <Table columns={columns} dataSource={data} pagination={false} />
    </Modal>
  );
};

export default UploadFilesDialog;
