import React, { useCallback, useEffect } from "react";
import { Button, Spin, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getInquiryDetails, setTableData } from "../store/dataSlice";
import { TiArrowBack } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
const InquiryDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedInquiry = useSelector(
    (state) => state.craneInquiry.state.selectedInquiry
  );

  const loading = useSelector((state) => state.craneInquiry.data.loading);

  const rowClassName = () => {
    return "!h-auto !py-2";
  };

  const columns = [
    {
      title: "Sl. No.",
      key: "index",
      width: 70,
      align: "center",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: 120,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
      width: 120,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 180,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "GST Number",
      dataIndex: "GSTn",
      key: "GSTn",
      width: 140,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: 140,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      width: 120,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Shock Absorber",
      dataIndex: "shockAbsorber",
      key: "shockAbsorber",
      width: 140,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      width: 140,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 100,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Spare Parts",
      dataIndex: "spare",
      key: "spare",
      width: 200,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">
              {text.map((item) => item.name).join(", ")}
            </span>
          </div>
        );
      },
    },
    {
      title: "Series",
      dataIndex: "series",
      key: "series",
      width: 100,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      width: 100,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Drawing",
      dataIndex: "drawing",
      key: "drawing",
      width: 100,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">
              {text ? "Yes" : "No"}
            </span>
          </div>
        );
      },
    },
    {
      title: "Drawing Format",
      dataIndex: "drawingFormat",
      key: "drawingFormat",
      width: 150,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">
              {text.join(", ")}
            </span>
          </div>
        );
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 300,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Temperature",
      dataIndex: "temperature",
      key: "temperature",
      width: 120,
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <span className="font-semibold text-gray-500">{text}</span>
          </div>
        );
      },
    },
  ];

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.inquiry.data.tableData
  );
  const data = useSelector(
    (state) => state.inquiry.data.inquiryDetailsList?.data
  );

  const fetchData = useCallback(() => {
    dispatch(
      getInquiryDetails({
        data: {
          pageIndex,
          pageSize,
          email: selectedInquiry.email,
        },
        headers: {
          connection: "cranebuffer",
        },
      })
    );
  }, [pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize]);

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  return (
    <div className="bg-white m-4 p-8 rounded-xl">
      <div className="flex justify-between items-center mb-4">
        <div>Inquiry Details</div>
        <Button
          className="flex bg-[#096CAE] text-white"
          onClick={() => navigate("/cranebuffer/inquiry")}
        >
          <span>
            <TiArrowBack className="text-lg" />
          </span>
          <p>Go Back</p>
        </Button>
      </div>
      <div>
        {loading ? (
          <div className="flex justify-center items-center h-screen">
            <Spin size="large" />
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            bordered
            scroll={{
              x: 1400,
            }}
            pagination={{
              current: pageIndex,
              pageSize: pageSize,
              total: total,
              showSizeChanger: true,
              pageSizeOptions: [5, 10, 20],
              onShowSizeChange: handleTableChange,
            }}
            tableLayout="fixed"
            onChange={handleTableChange}
            rowClassName={rowClassName}
          />
        )}
      </div>
    </div>
  );
};

export default InquiryDetail;
