import ApiService from "./ApiService";

export async function apiGetAllPrice(data, headers) {
  return ApiService.fetchData({
    url: "/api/data/get",
    method: "post",
    data,
    headers: {
      "x-db-connection": headers.connection,
    },
  });
}

export async function apiPostData(data, headers) {
  return ApiService.fetchData({
    url: "/api/data/create",
    method: "post",
    data,
    headers: {
      // "Content-Type": "multipart/form-data",
      "x-db-connection": headers.connection,
    },
  });
}

export async function apiPostShockData(data, headers) {
  return ApiService.fetchData({
    url: "/api/data/shock/create",
    method: "patch",
    data,
    headers: {
      // "Content-Type": "multipart/form-data",
      "x-db-connection": headers.connection,
    },
  });
}

export async function apiPutData(data, headers) {
  return ApiService.fetchData({
    url: "/api/data/update",
    method: "patch",
    data,
    headers: {
      "x-db-connection": headers.connection,
    },
  });
}

export async function apiputPrice(data) {
  return ApiService.fetchData({
    url: "/api/priceListDetails/update",
    method: "put",
    data,
  });
}

export async function apiGetAllPriceTable(data) {
  return ApiService.fetchData({
    url: "/api/priceList/get",
    method: "Post",
    data,
  });
}

export async function apiGetpriceListDetails(data) {
  return ApiService.fetchData({
    url: "/api/priceListDetails/get",
    method: "Post",
    data,
  });
}
