import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Typography, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { apiLogin } from "../../services/AuthService";
import { useDispatch } from "react-redux";
import { getMenu } from "../../components/Sidebar/store/dataSlice";
import { persistor } from "../../store/index";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onFinishFailed = (errorInfo) => {
    ////console.log("Failed:", errorInfo);
  };

  const onFinish = async () => {
    try {
      setLoading(true);

      localStorage.clear();
      persistor.flush();
      persistor.purge();

      const response = await apiLogin(
        { email, password },
        { connection: "shockabsorber" }
      );

      if (response.status === 200) {
        api.success({
          message: "Success",
          description: "Login successful!",
        });
        setLoading(false);
        localStorage.setItem("token", response.data.token);
        window.location.href = "/dashboard";
      } else {
        api.error({
          message: "Error",
          description: "Unexpected error occurred.",
        });
      }
    } catch (err) {
      setLoading(false);
      api["error"]({
        message: "Error",
        description: err.message,
      });
    }
  };

  return (
    <div className="appBg">
      {contextHolder}
      <div className="loginForm">
        <h2 className="text-2xl font-bold mb-4 flex">
          Welcome <p className="text-3xl mt-[-6px]">&#128075;</p>
        </h2>
        <p className="text-gray-500 mb-4">Please Login to continue&#128071;</p>
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>

          <Form.Item className="!mb-3">
            <Button
              type="primary"
              htmlType="submit"
              block
              className="btn"
              loading={loading}
              disabled={loading}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Checkbox>Remember me</Checkbox>
          </div>
          <div className="text-left text-xs text-blue-600">
            <Link>Forgot Password?</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
