import React, { useCallback, useEffect, useState } from "react";
import { Button, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getPrice, setTableData } from "../store/dataSlice";
import { setSeriesFilters, toggleNewFilter } from "../store/stateSlice";

const { Option } = Select;

const PriceFilter = () => {
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.shockPrice.state.newFilter);
  const { pageIndex, pageSize } = useSelector(
    (state) => state.shockPrice.data.tableData
  );
  const seriesOptions = ["ACS", "AD", "ACX", "AC"];

  const [selectedSeries, setSelectedSeries] = useState([]);

  const handleChange = (selectedSeries) => {
    setSelectedSeries(selectedSeries);
  };

  const handleApply = useCallback(() => {
    dispatch(setSeriesFilters(selectedSeries));
    dispatch(
      getPrice({
        data: {
          pageIndex,
          pageSize,
          seriesFilter: selectedSeries,
        },
        headers: {
          connection: "shockabsorber",
        },
      })
    );
  }, [pageIndex, pageSize, dispatch, selectedSeries]);

  const handleClear = () => {
    setSelectedSeries([]);
    dispatch(setSeriesFilters([]));
    dispatch(toggleNewFilter(false));
    dispatch(
      getPrice({
        data: {
          pageIndex,
          pageSize,
        },
        headers: {
          connection: "shockabsorber",
        },
      })
    );
    dispatch(setTableData({ pageIndex: 1, pageSize }));
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        gap: "16px",
        marginBottom: "22px",
        alignItems: "center",
        border: "1px dotted #E5E5E5",
        padding: "16px",
      }}
    >
      <div style={{ gridColumn: "1 / 2" }}>
        <Select
          mode="multiple"
          allowClear
          placeholder="Select Series"
          style={{ width: "100%" }}
          onChange={handleChange}
        >
          {seriesOptions.map((series) => (
            <Option key={series} value={series}>
              {series}
            </Option>
          ))}
        </Select>
      </div>

      <div style={{ gridColumn: "2 / 4" }}></div>

      <div style={{ gridColumn: "4 / 5" }} className="flex gap-2">
        <Button
          className="bg-[#096CAE] text-white"
          onClick={handleApply}
          style={{ width: "50%", height: "40px" }}
        >
          Apply
        </Button>
        <Button
          className=" border-[#096CAE] text-black"
          onClick={handleClear}
          style={{ width: "50%", height: "40px" }}
        >
          Clear
        </Button>
      </div>

      <style>
        {`
            .ant-select-selector {
                display: flex !important;
                align-items: center !important;
                padding-top: 10px !important;
                padding-bottom: 10px !important;
            }
            .ant-select-selection-placeholder {
                display: flex !important;
                align-items: center !important;
                padding-left: 8px !important;
            }
        `}
      </style>
    </div>
  );
};

export default PriceFilter;
