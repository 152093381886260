import React from "react";
import styled, { keyframes } from "styled-components";

const float = keyframes`
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
`;

const DashboardContainer = styled.div`
  margin: 0;
  font-family: "Arial", sans-serif;
  background: linear-gradient(135deg, #4c8bf5, #9c47fc);
  color: #ffffff;
  overflow: hidden;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const WelcomeSection = styled.div`
  h1 {
    font-size: 4rem;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  }

  p {
    font-size: 1.5rem;
    opacity: 0.9;
  }
`;

const FloatingSvg = styled.svg`
  position: absolute;
  animation: ${float} 5s infinite ease-in-out;

  &.svg1 {
    top: 15%;
    left: 50%;
    width: 80px;
    animation-delay: 0s;
  }

  &.svg2 {
    top: 70%;
    left: 70%;
    width: 100px;
    animation-delay: 2s;
  }

  &.svg3 {
    top: 40%;
    left: 20%;
    width: 60px;
    animation-delay: 4s;
  }

  &.svg4 {
    top: 20%;
    left: 90%;
    width: 80px;
    height: 80px;
    animation-delay: 1s;
  }
`;

const Dashboard = () => {
  return (
    <DashboardContainer>
      <WelcomeSection>
        <h1>Welcome to the Admin Panel of Adonitech</h1>
        <p>Manage everything seamlessly and efficiently.</p>
      </WelcomeSection>

      <FloatingSvg
        className="svg1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        fill="#FFD700"
      >
        <circle cx="32" cy="32" r="32" />
      </FloatingSvg>

      <FloatingSvg
        className="svg2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        fill="#00FF7F"
      >
        <rect x="16" y="16" width="32" height="32" rx="8" />
      </FloatingSvg>

      <FloatingSvg
        className="svg3"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        fill="#FF69B4"
      >
        <polygon points="32,0 64,64 0,64" />
      </FloatingSvg>

      <FloatingSvg
        className="svg4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        fill="#00CED1"
      >
        <polygon points="32,4 48,16 48,48 32,60 16,48 16,16" />
      </FloatingSvg>
    </DashboardContainer>
  );
};

export default Dashboard;
