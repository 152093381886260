import axios from "axios";
// import appConfig from '../configs/app.config'
// import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from '../constants/api.constant'
// import { PERSIST_STORE_NAME } from '../constants/app.constant'
// import { onSignOutSuccess } from '../store/auth/sessionSlice'

const unauthorizedCode = [401];

const apikey = "hjh4653dsiivy457468asdfe";
const BaseService = axios.create({
  timeout: 60000,
  // baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: "http://localhost:4000",
  baseURL: "https://admin.cranebuffer.com",
});

BaseService.interceptors.request.use(
  (config) => {
    const rawPersistData = localStorage.getItem("token");

    let accessToken = rawPersistData ? rawPersistData : null;

    config.headers["token"] = `${accessToken}`;

    // config.headers["apikey"] = "hjh4653dsiivy457468asdfe";
    // config.headers["Content-Type"] = "application/json";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

BaseService.interceptors.response.use(
  (response) => {
    if (response.data.code === 403) {
      localStorage.removeItem("UserData");
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    return response;
  },
  (error) => {
    const { response } = error;
    return Promise.reject(error);
  }
);

export default BaseService;
