import React, { useState, useRef, useEffect } from "react";
import { Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const CustomUpload = ({
  name,
  fileType,
  accept,
  setFieldValue,
  resetTrigger,
  className,
}) => {
  const [fileList, setFileList] = useState([]);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (resetTrigger) {
      setFileList([]);
      setFieldValue(name, []);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  }, [resetTrigger, name, setFieldValue]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = [];
    const allowedExtensions = accept.replace(/\./g, "").split(",");

    files.forEach((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        message.error(`Only files of type ${accept} are allowed.`);
        return;
      }

      validFiles.push(file);
    });

    const updatedFileList = [...fileList, ...validFiles];
    setFileList(updatedFileList);
    setFieldValue(name, updatedFileList);
  };

  const handleRemove = (fileToRemove) => {
    const updatedFileList = fileList.filter((file) => file !== fileToRemove);
    setFileList(updatedFileList);
    setFieldValue(name, updatedFileList);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div className={className}>
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
        accept={accept}
        multiple
        name={name}
        onChange={handleFileChange}
      />

      <Button
        icon={<UploadOutlined />}
        className="w-full md:w-70 h-10"
        onClick={() => fileInputRef.current.click()}
        style={{ marginBottom: "16px" }}
      >
        Upload {fileType}
      </Button>

      {fileList.length > 0 && (
        <div style={{ marginTop: 4 }}>
          <ul>
            {fileList.map((file, index) => (
              <li key={index}>
                {file.name}{" "}
                <Button
                  size="small"
                  danger
                  onClick={() => handleRemove(file)}
                  style={{ marginLeft: 10 }}
                >
                  Remove
                </Button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomUpload;
