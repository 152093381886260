import React, { useState, useRef, useEffect } from "react";
import { Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const CustomUpload = ({ fileType, setFieldValue, name, resetTrigger }) => {
  const [fileList, setFileList] = useState([]);

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (resetTrigger) {
      setFileList([]);
      setFieldValue(name, []);

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  }, [resetTrigger, name, setFieldValue]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = [];

    files.forEach((file) => {
      if (!file) {
        return;
      }

      const fileTypes = (file.type || file.name.split(".").pop()).toLowerCase();

      if (Array.isArray(fileType)) {
        if (!fileType.includes(fileTypes)) {
          message.error(`Only files of type ${fileType} are allowed`);

          return;
        }
      } else {
        if (fileTypes !== fileType) {
          message.error(`Only files of type ${fileType} are allowed`);

          return;
        }
      }
      // if (fileTypes !== fileType) {
      //   message.error(`Only files of type ${fileType} are allowed`);
      // } else {
      //   validFiles.push(file);
      // }

      validFiles.push(file);
    });

    const updatedFileList = [...fileList, ...validFiles];

    setFileList(updatedFileList);
    setFieldValue(name, updatedFileList);
  };

  const handleRemove = (fileToRemove) => {
    const updatedFileList = fileList.filter((file) => file !== fileToRemove);
    setFileList(updatedFileList);
    setFieldValue(name, updatedFileList);

    const fileInput = document.querySelector(`input[name="${name}"]`);
    if (fileInput) {
      fileInput.value = "";
    }
  };

  // const fileInputRef = React.createRef();

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
        accept={fileType}
        multiple
        name={name}
        onChange={handleFileChange}
      />

      <Button
        icon={<UploadOutlined />}
        className="w-full md:w-56 h-10"
        onClick={() => fileInputRef.current.click()}
      >
        Upload {name}
      </Button>

      {/* Display selected files */}
      {fileList.length > 0 && (
        <div style={{ marginTop: 10 }}>
          <ul>
            {fileList.map((file) => (
              <li key={file.uid}>
                {file.name}{" "}
                <Button
                  size="small"
                  danger
                  onClick={() => handleRemove(file)}
                  style={{ marginLeft: 10 }}
                >
                  Remove
                </Button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomUpload;
