import React, { useEffect, useState } from "react";
import CatalogReducer from "./store";
import { injectReducer } from "../../../store";
import { useSelector, useDispatch } from "react-redux";
import {
  getEmployeeTaskReport,
  //   getPrice,
  setTableData,
} from "./store/dataSlice";
import { FaFilter } from "react-icons/fa";
import { Button, Select, DatePicker, Drawer } from "antd";
// import PriceTable from "./components/PriceTable";
import { LuPlus } from "react-icons/lu";
import {
  selectedCatalog,
  setSelectedCatalog,
  toggleNewDialog,
  toggleNewFilter,
} from "./store/stateSlice";
import { CloseOutlined } from "@mui/icons-material";
import CatalogTable from "./components/CatalogTable";
import CatalogForm from "./components/CatalogForm";
// import PriceForm from "./components/PriceForm";
// import PriceSearch from "./components/PriceSearch";
// import StatusFilter from "./components/StatusFilter";
// import PriceFilter from "./components/PriceFilter";

injectReducer("craneCatalog", CatalogReducer);
const CraneBufferCatalog = () => {
  const dispatch = useDispatch();
  const dialog = useSelector((state) => state.craneCatalog.state.newDialog);
  const filter = useSelector((state) => state.craneCatalog.state.newFilter);
  const { pageIndex, pageSize } = useSelector(
    (state) => state.craneCatalog.data.tableData
  );

  const onDialog = () => {
    dispatch(setSelectedCatalog(null));
    dispatch(toggleNewDialog(true));
  };

  const onClose = () => {
    dispatch(toggleNewDialog(false));
  };

  const handleFilterClick = () => {
    dispatch(toggleNewFilter(!filter));
  };

  useEffect(() => {
    // dispatch(setSeriesFilters([]));
    dispatch(toggleNewFilter(false));
    // dispatch(
    //   getPrice({
    //     data: {
    //       pageIndex: 1,
    //       pageSize,
    //     },
    //     headers: {
    //       connection: "cranebuffer",
    //     },
    //   })
    // );
    dispatch(setTableData({ pageIndex: 1, pageSize }));
  }, []);

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">Catalogs</div>
          <div className="flex">
            <div className="flex items-center">
              {/* <div
                className="bg-[#096CAE] p-[11.5px] rounded mr-4 text-white cursor-pointer"
                onClick={handleFilterClick}
              >
                <FaFilter />
              </div> */}
              <div>{/* <PriceSearch /> */}</div>
              <div>
                <Button
                  style={{
                    backgroundColor: "#096CAE",
                    color: "#ffff",
                    display: "flex",
                    padding: "18px",
                    borderRadius: "6px",
                  }}
                  onClick={onDialog}
                >
                  <LuPlus />
                  <p>Create</p>
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="filter mb-4">
          <StatusFilter />
        </div> */}
        {/* {filter && <PriceFilter />} */}
        <CatalogTable />
      </div>

      <Drawer
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>Crane Buffer</span>
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={onClose}
              style={{ marginLeft: "16px", color: "#096CAE" }}
            />
          </div>
        }
        placement="right"
        closable={false}
        onClose={onClose}
        visible={dialog}
        width={1000}
      >
        <CatalogForm />
      </Drawer>
    </>
  );
};

export default CraneBufferCatalog;
