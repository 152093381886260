import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import { Formik, Form as FormikForm } from "formik";
import { Form, Input, Row, Col, Button, notification } from "antd";
import * as Yup from "yup";
import { createData, getCatalog, updateCatalog } from "../store/dataSlice";
import CustomUpload from "./Upload";
const CatalogForm = () => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.shockCatalog.data.tableData
  );
  const [resetTrigger, setResetTrigger] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectedCatalogs = useSelector(
    (state) => state.shockCatalog.state?.selectedCatalogs
  );

  const data = useSelector(
    (state) => state.shockCatalog.data.catalogList?.data
  );

  const validationSchema = Yup.object({
    // title: Yup.string().required("Title is required"),
    // pdfFile: Yup.mixed().required("PDF file is required"),
    // thumbnail: Yup.mixed().required("Thumbnail is required"),
  });

  const handleCancel = () => {
    dispatch(toggleNewDialog(false));
  };

  useEffect(() => {
    if (selectedCatalogs) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [selectedCatalogs]);

  const initialValues = {
    title: selectedCatalogs?.title || "",
    pdfFile: selectedCatalogs?.pdfFile || null,
    thumbnail: selectedCatalogs?.thumbnail || null,
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();

    if (values.title) {
      formData.append("title", values.title);
    }

    if (values.pdfFile && Array.isArray(values.pdfFile)) {
      formData.append("pdfFile", values.pdfFile[0]);
    }

    if (values.thumbnail && Array.isArray(values.thumbnail)) {
      formData.append("thumbnail", values.thumbnail[0]);
    }

    if (edit) {
      formData.append("id", selectedCatalogs?._id);
    }

    // formData.forEach((value, key) => {
    //   console.log("key value", key, value);
    // });

    setLoading(true);

    try {
      const action = edit
        ? await dispatch(
            updateCatalog({
              data: formData,
              headers: {
                connection: "shockabsorber",
              },
            })
          )
        : await dispatch(
            createData({
              data: formData,
              headers: {
                connection: "shockabsorber",
              },
            })
          );

      if (action.payload.code === 200) {
        resetForm();

        setResetTrigger((prev) => !prev);
        dispatch(
          getCatalog({
            data: {
              pageIndex,
              pageSize,
            },
            headers: {
              connection: "shockabsorber",
            },
          })
        );

        api.success({
          message: "Form Submitted Successfully.",
        });
      } else {
        console.error(
          "Error occurred during form submission:",
          action.payload.error
        );
      }
      return action.payload.code;
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  const handleSaveAndClose = async (submitForm) => {
    submitForm().then((actionCode) => {
      if (actionCode === 200) {
        handleCancel();
      }
    });
  };

  return (
    <>
      <div className="">
        {contextHolder}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            submitForm,
            setFieldValue,
          }) => (
            <FormikForm>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.title && errors.title ? errors.title : ""}
                    validateStatus={
                      touched.title && errors.title ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Title</span>
                    <Input
                      name="title"
                      placeholder="Enter title"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.pdfFile && errors.pdfFile ? errors.pdfFile : ""
                    }
                    validateStatus={
                      touched.pdfFile && errors.pdfFile ? "error" : undefined
                    }
                  >
                    <span className="text-xs">PDF File</span>
                    <CustomUpload
                      name="pdfFile"
                      fileType="PDF"
                      accept=".pdf"
                      setFieldValue={setFieldValue}
                      resetTrigger={resetTrigger}
                      className="w-full"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.thumbnail && errors.thumbnail
                        ? errors.thumbnail
                        : ""
                    }
                    validateStatus={
                      touched.thumbnail && errors.thumbnail
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Thumbnail</span>
                    <CustomUpload
                      name="thumbnail"
                      fileType="Images"
                      accept=".jpg,.jpeg,.png,.gif,.bmp,.tiff,.svg"
                      setFieldValue={setFieldValue}
                      resetTrigger={resetTrigger}
                      className="w-full"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item className="flex justify-end mt-6">
                    <div className="fixed bottom-0 right-0 w-[1000px] p-4 bg-white shadow-lg z-10 flex justify-end items-center">
                      <Button
                        onClick={handleCancel}
                        type="primary"
                        className="mr-2"
                      >
                        Cancel
                      </Button>
                      <div className="flex items-center">
                        {!edit && (
                          <Button
                            type="primary"
                            className="!p-2"
                            loading={loading}
                            disabled={loading}
                            onClick={() => handleSaveAndClose(submitForm)}
                          >
                            Save and Close
                          </Button>
                        )}
                        {edit && (
                          <Button
                            type="primary"
                            htmlType="submit"
                            onClick={handleCancel}
                            loading={loading}
                            disabled={loading}
                          >
                            Update
                          </Button>
                        )}
                      </div>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </FormikForm>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CatalogForm;
