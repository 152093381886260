import { createSlice } from "@reduxjs/toolkit";

const stateSlice = createSlice({
  name: "priceList/state",
  initialState: {
    deleteConfirmation: false,
    selectedPrices: "",
    seriesFilters: [],
    newFilter: false,
    newDialog: false,
    uploadDialog: false,
  },
  reducers: {
    toggleDeleteConfirmation: (state, action) => {
      state.deleteConfirmation = action.payload;
    },
    setSelectedPrices: (state, action) => {
      state.selectedPrices = action.payload;
    },
    toggleNewDialog: (state, action) => {
      state.newDialog = action.payload;
    },
    toggleUploadDialog: (state, action) => {
      state.uploadDialog = action.payload;
    },

    toggleNewFilter: (state, action) => {
      state.newFilter = action.payload;
    },

    setSeriesFilters: (state, action) => {
      state.seriesFilters = action.payload;
    },
  },
});

export const {
  toggleDeleteConfirmation,
  setSelectedPrices,
  toggleNewDialog,
  toggleUploadDialog,
  toggleNewFilter,
  setSeriesFilters,
} = stateSlice.actions;

export default stateSlice.reducer;
