import React, { useCallback, useEffect, useState } from "react";
import { Table, Modal, Skeleton, Flex, Spin } from "antd";
import { FaRegEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getCatalog, deleteData } from "../store/dataSlice";
import { setSelectedCatalog, toggleNewDialog, toggleDeleteConfirmation } from "../store/stateSlice";
import { MdDelete } from "react-icons/md";
import { MdDownload } from "react-icons/md";
import { FaEye } from "react-icons/fa";

const CatalogTable = () => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const data = useSelector(
    (state) => state.shockCatalog.data.catalogList?.data
  );
  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.shockCatalog.data.tableData
  );

  const loading = useSelector((state) => state.shockCatalog.data.loading);

  const fetchData = useCallback(() => {
    dispatch(
      getCatalog({
        data: {
          pageIndex,
          pageSize,
          // ...(filter && { seriesFilter }),
        },
        headers: {
          connection: "shockabsorber",
        },
      })
    );
  }, [pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize]);

  const BASE_URL = "https://admin.cranebuffer.com/api";

  const handleFileClick = (filePath) => {
    const fileUrl = `${BASE_URL}${filePath}`;
    // const fileUrl = process.env.REACT_APP_BASE_URL + filePath;
    setIsLoading(true);
    setModalContent(fileUrl);
    setIsModalOpen(true);

    setTimeout(() => {
      handleFileLoad();
    }, 4000);
  };

  const handleFileLoad = () => {
    setIsLoading(false);
  };

  const onEdit = (record) => {
    dispatch(setSelectedCatalog(record));
    dispatch(toggleNewDialog(true));
  };

  const onDelete = async (record) => {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content: "This action cannot be undone.",
      okText: "Yes, Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await dispatch(
            deleteData({
              data: { id: record._id },
              headers: { connection: "shockabsorber" },
            })
          ).unwrap();
          fetchData();
        } catch (error) {
          console.error("Failed to delete item:", error);
        }
      },
    });
  };


  // const handleFileDownload = (filePath) => {
  //   const fileUrl = `${BASE_URL}${filePath}`;
  //   const link = document.createElement("a");
  //   link.href = fileUrl;
  //   link.download = filePath.split("/").pop(); // Extracts the file name from the URL
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };



  const handleFileDownload = async (filePath) => {
    try {
      const fileUrl = `${BASE_URL}${filePath}`; 
      const response = await fetch(fileUrl, {
        method: "GET",
        headers: {
          connection: "shockabsorber",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch file.");
      }

      const blob = await response.blob();
      const link = document.createElement("a");

      link.href = URL.createObjectURL(blob); 
      link.download = filePath.split("/").pop(); 
      document.body.appendChild(link);
      link.click(); 
      document.body.removeChild(link); 
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const columns = [
    {
      title: <span className="text-gray-600 font-semibold">Action</span>,
      dataIndex: "action",
      fixed: "left",
      width: 80,
      align: "center",
      render: (_, record) => (
        <div className="flex justify-center items-center">
          <span
            onClick={() => onEdit(record)}
            className="text-xl text-[#096CAE] cursor-pointer"
          >
            <FaRegEdit />
          </span>

          <span
            onClick={() => onDelete(record)}
            className="text-xl text-red-500 ml-2 cursor-pointer"
          >
            <MdDelete />
          </span>
        </div>
      ),
    },
    {
      title: <span className="text-gray-600 font-semibold">Title</span>,
      dataIndex: "title",
      key: "title",
      width: "250px",
      align: "center",
      render: (text) => (
        <div>
          <span className="font-semibold text-gray-500">{text}</span>
        </div>
      ),
    },
    {
      title: <span className="text-gray-600 font-semibold">PDF File</span>,
      dataIndex: "pdfFile",
      key: "pdf_File",
      width: "150px",
      align: "center",
      render: (text) => (
        <div>
          <button
            onClick={() => handleFileClick(text)}
            className="text-blue-500 underline mr-2"
          >
            View

          </button>

          <span>
            <button
              onClick={() => handleFileDownload(text)}
              className="text-red-500 underline ml-2"
            >
              Download
            </button>
          </span>
        </div>
      ),
    },
    {
      title: <span className="text-gray-600 font-semibold">Thumbnail</span>,
      dataIndex: "thumbnail",
      key: "thumbnail",
      width: "150px",
      align: "center",
      render: (text) => (
        <div>
        <button
          onClick={() => handleFileClick(text)}
          className="text-blue-500  underline mr-2"
        >
          View

        </button>

        <span>
          <button
            onClick={() => handleFileDownload(text)}
            className="text-red-500  underline ml-2"
          >
            Download
          </button>
        </span>
      </div>
      ),
    },
  ];

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          bordered
          tableLayout="fixed"
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20],
          }}
        />
      )}
      <Modal
        title="File Viewer"
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        centered
        width={800}
      >
        <div
          className="flex justify-center items-center"
          style={{ height: "100%" }}
        >
          {isLoading ? (
            <div style={{ textAlign: "center" }}>
              <Skeleton.Image
                active={true}
                style={{ width: 400, height: 300 }}
              />
            </div>
          ) : /.pdf$/i.test(modalContent) ? (
            <iframe
              src={modalContent}
              title="PDF Viewer"
              width="100%"
              height="500px"
              style={{
                border: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          ) : (
            <img
              src={modalContent}
              alt="Thumbnail"
              style={{ maxWidth: "100%", maxHeight: "500px" }}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default CatalogTable;
