import React, { useEffect, useRef, useState } from "react";
import { Formik, Field, Form as FormikForm } from "formik";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  Modal,
  Skeleton,
  notification,
  Card,
} from "antd";
import { MdDownload } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedIndex,
  setSelectedPrices,
  toggleNewDialog,
} from "../store/stateSlice";
import { createData, getPrice, putData } from "../store/dataSlice";
import { UploadOutlined } from "@mui/icons-material";
import CustomUpload from "./Upload";

const PriceForm = () => {
  const dispatch = useDispatch();
  const [filePaths, setFilePaths] = useState(null);
  const [edit, setEdit] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.cranePrice.data.tableData
  );

  const currentIndex = useSelector(
    (state) => state.cranePrice.state.selectedIndex
  );

  const [resetTrigger, setResetTrigger] = useState(false);
  const fileInputRef = useRef(null);

  const selectedPrices = useSelector(
    (state) => state.cranePrice.state?.selectedPrices
  );

  const data = useSelector((state) => state.cranePrice.data.priceList?.data);
  const searchFilter = useSelector(
    (state) => state.cranePrice.state?.searchFilter
  );

  const validationSchema = Yup.object({
    Model: Yup.string().required("Required"),
    NEWPRICE: Yup.string().required("Required"),
  });

  const handleCancel = () => {
    dispatch(toggleNewDialog(false));
  };

  useEffect(() => {
    if (selectedPrices) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [selectedPrices]);

  const initialValues = {
    Model: selectedPrices?.Model || "",
    NEWPRICE: selectedPrices?.NEWPRICE || 0,
    Stroke: selectedPrices?.Stroke || 0,
    SERIAL_NO: selectedPrices?.["SERIAL NO"] || 0,
    THREADING: selectedPrices?.THREADING || "",
    nmperstroke: selectedPrices?.nmperstroke || 0,
    nmperhr: selectedPrices?.nmperhr || 0,
    EMASSMIN: selectedPrices?.EMASSMIN || 0,
    EMASSMAX: selectedPrices?.EMASSMAX || 0,
    SFMIN: selectedPrices?.SFMIN || 0,
    SFMAX: selectedPrices?.SFMAX || 0,
    WEIGHT_IN_GMS: selectedPrices?.["WEIGHT IN GMS"] || 0,
    Front_Flange: selectedPrices?.["Front Flange"] || 0,
    Rear_Flange: selectedPrices?.["Rear Flange"] || 0,
    Clevis_Mount: selectedPrices?.["Clevis Mount"] || 0,
    Side_Load_Adapter: selectedPrices?.["Side Load Adapter"] || "",
    Stop_Coller: selectedPrices?.["Stop Coller"] || 0,
    RATE_PER_NM: selectedPrices?.["RATE PER NM"] || 0,
    NO_CAP: selectedPrices?.["NO CAP"] || 0,
    METTALIC_CAP: selectedPrices?.["METTALIC CAP"] || 0,
    METALLIC_CAP_WITH_PU_BUTTON:
      selectedPrices?.["METALLIC CAP WITH PU BUTTON"] || 0,
    LOCK_NUT_EXTRA: selectedPrices?.["LOCK NUT EXTRA"] || 0,
    front_flange_iges: selectedPrices?.front_flange_iges || null,
    front_flange_step: selectedPrices?.front_flange_step || null,
    front_flange_pdf: selectedPrices?.front_flange_pdf || null,
    front_flange_dxf: selectedPrices?.front_flange_dxf || null,
    rear_flange_iges: selectedPrices?.rear_flange_iges || null,
    rear_flange_step: selectedPrices?.rear_flange_step || null,
    rear_flange_pdf: selectedPrices?.rear_flange_pdf || null,
    rear_flange_dxf: selectedPrices?.rear_flange_dxf || null,
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();

    for (const key in values) {
      if (values.hasOwnProperty(key) && values[key] !== null) {
        if (key.startsWith("rear_flange_") || key.startsWith("front_flange_")) {
          if (Array.isArray(values[key])) {
            values[key].forEach((file, index) => {
              if (file instanceof File) {
                formData.append(key, file);
              }
            });
          } else if (values[key] instanceof File) {
            formData.append(key, values[key]);
          }
        } else {
          formData.append(key, values[key]);
        }
      }
    }

    // formData.forEach((value, key) => {
    //   console.log("key value", key, value);
    // });

    if (edit) {
      formData.append("id", selectedPrices?._id);
    }

    try {
      const action = edit
        ? await dispatch(
          putData({
            data: formData,
            headers: {
              connection: "cranebuffer",
            },
          })
        )
        : await dispatch(
          createData({
            data: formData,
            headers: {
              connection: "cranebuffer",
            },
          })
        );

      if (action.payload.code === 200) {
        resetForm();

        setResetTrigger((prev) => !prev);
        for (const key in values) {
          if (values[key] instanceof File || Array.isArray(values[key])) {
            values[key] = null;
          }
        }
        dispatch(
          getPrice({
            data: {
              searchFilter,
              pageIndex,
              pageSize,
            },
            headers: {
              connection: "cranebuffer",
            },
          })
        );

        api.success({
          message: "Form Submitted Successfully.",
        });
      } else {
        console.error(
          "Error occurred during form submission:",
          action.payload.error
        );
      }
      return action.payload.code;
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSaveAndClose = async (submitForm) => {
    submitForm().then((actionCode) => {
      if (actionCode === 200) {
        handleCancel();
      }
    });
  };

  const handleNext = (values) => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    if (currentIndex < total - 1) {
      dispatch(setSelectedIndex(currentIndex + 1));
      dispatch(setSelectedPrices(data[currentIndex + 1]));
    }
  };

  const handlePrevious = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    if (currentIndex > 0) {
      dispatch(setSelectedIndex(currentIndex - 1));
      dispatch(setSelectedPrices(data[currentIndex - 1]));
    }
  };

  useEffect(() => {
    const fetchFilePaths = async () => {
      const response = await fetch(
        "https://admin.cranebuffer.com/api/data/get/files",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-db-connection": "cranebuffer",
          },
          body: JSON.stringify({ Model: selectedPrices?.Model }),
        }
      );
      const data = await response.json();

      setFilePaths(data);
    };

    fetchFilePaths();
  }, []);

  const items = ["Front Flange", "Rear Flange"];
  const fileTypes = [
    { name: "pdf", label: "PDF", accept: ".pdf", type: "application/pdf" },
    { name: "iges", label: "IGES", accept: ".IGS", type: "igs" },
    { name: "step", label: "STEP", accept: ".STEP", type: "step" },
    {
      name: "dxf",
      label: "DXF and DWG",
      accept: ".dxf, .dwg",
      type: ["dxf", "dwg"],
    },
  ];


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const BASE_URL = "https://admin.cranebuffer.com/api";

  const handleFileClick = (filePath) => {
    console.log(filePath);

    const fileUrl = `${BASE_URL}/${filePath}`;
    setIsLoading(true);
    setModalContent(fileUrl);
    setIsModalOpen(true);

    setTimeout(() => {
      handleFileLoad();
    }, 4000);
  };

  const handleFileLoad = () => {
    setIsLoading(false);
  };

  const handleFileDownload = async (filePath) => {
    try {
      const fileUrl = `${BASE_URL}/${filePath}`;
      const response = await fetch(fileUrl, {
        method: "GET",
        headers: {
          connection: "cranebuffer",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch file.");
      }

      const blob = await response.blob();
      const link = document.createElement("a");

      link.href = URL.createObjectURL(blob);
      link.download = filePath.split("/").pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };


  return (
    <>
      <div className="">
        {contextHolder}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            submitForm,
            setFieldValue,
          }) => (
            <FormikForm>
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.Model && errors.Model ? errors.Model : ""}
                    validateStatus={
                      touched.Model && errors.Model ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Model</span>
                    <Input
                      name="Model"
                      placeholder="Model"
                      value={values.Model}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.NEWPRICE && errors.NEWPRICE ? errors.NEWPRICE : ""
                    }
                    validateStatus={
                      touched.NEWPRICE && errors.NEWPRICE ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Price</span>
                    <Input
                      name="NEWPRICE"
                      placeholder="Price"
                      value={values.NEWPRICE}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["SERIAL NO"] && errors["SERIAL NO"]
                        ? errors["SERIAL NO"]
                        : ""
                    }
                    validateStatus={
                      touched["SERIAL NO"] && errors["SERIAL NO"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">SERIAL NO</span>
                    <Input
                      name="SERIAL NO"
                      placeholder="SERIAL NO"
                      value={values["SERIAL NO"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.Stroke && errors.Stroke ? errors.Stroke : ""}
                    validateStatus={
                      touched.Stroke && errors.Stroke ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Stroke</span>
                    <Input
                      name="Stroke"
                      placeholder="Stroke"
                      value={values.Stroke}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.THREADING && errors.THREADING
                        ? errors.THREADING
                        : ""
                    }
                    validateStatus={
                      touched.THREADING && errors.THREADING
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">THREADING</span>
                    <Input
                      name="THREADING"
                      placeholder="THREADING"
                      value={values.THREADING}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.nmperstroke && errors.nmperstroke
                        ? errors.nmperstroke
                        : ""
                    }
                    validateStatus={
                      touched.nmperstroke && errors.nmperstroke
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">nmperstroke</span>
                    <Input
                      name="nmperstroke"
                      placeholder="nmperstroke"
                      value={values.nmperstroke}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.nmperhr && errors.nmperhr ? errors.nmperhr : ""
                    }
                    validateStatus={
                      touched.nmperhr && errors.nmperhr ? "error" : undefined
                    }
                  >
                    <span className="text-xs">nmperhr</span>
                    <Input
                      name="nmperhr"
                      placeholder="nmperhr"
                      value={values.nmperhr}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.EMASSMIN && errors.EMASSMIN ? errors.EMASSMIN : ""
                    }
                    validateStatus={
                      touched.EMASSMIN && errors.EMASSMIN ? "error" : undefined
                    }
                  >
                    <span className="text-xs">EMASSMIN</span>
                    <Input
                      name="EMASSMIN"
                      placeholder="EMASSMIN"
                      value={values.EMASSMIN}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.EMASSMAX && errors.EMASSMAX ? errors.EMASSMAX : ""
                    }
                    validateStatus={
                      touched.EMASSMAX && errors.EMASSMAX ? "error" : undefined
                    }
                  >
                    <span className="text-xs">EMASSMAX</span>
                    <Input
                      name="EMASSMAX"
                      placeholder="EMASSMAX"
                      value={values.EMASSMAX}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.SFMIN && errors.SFMIN ? errors.SFMIN : ""}
                    validateStatus={
                      touched.SFMIN && errors.SFMIN ? "error" : undefined
                    }
                  >
                    <span className="text-xs">SFMIN</span>
                    <Input
                      name="SFMIN"
                      placeholder="SFMIN"
                      value={values.SFMIN}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.SFMAX && errors.SFMAX ? errors.SFMAX : ""}
                    validateStatus={
                      touched.SFMAX && errors.SFMAX ? "error" : undefined
                    }
                  >
                    <span className="text-xs">SFMAX</span>
                    <Input
                      name="SFMAX"
                      placeholder="SFMAX"
                      value={values.SFMAX}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["WEIGHT IN GMS"] && errors["WEIGHT IN GMS"]
                        ? errors["WEIGHT IN GMS"]
                        : ""
                    }
                    validateStatus={
                      touched["WEIGHT IN GMS"] && errors["WEIGHT IN GMS"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">WEIGHT IN GMS</span>
                    <Input
                      name="WEIGHT IN GMS"
                      placeholder="WEIGHT IN GMS"
                      value={values["WEIGHT IN GMS"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["Front Flange"] && errors["Front Flange"]
                        ? errors["Front Flange"]
                        : ""
                    }
                    validateStatus={
                      touched["Front Flange"] && errors["Front Flange"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Front Flange</span>
                    <Input
                      name="Front Flange"
                      placeholder="Front Flange"
                      value={values["Front Flange"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["Rear Flange"] && errors["Rear Flange"]
                        ? errors["Rear Flange"]
                        : ""
                    }
                    validateStatus={
                      touched["Rear Flange"] && errors["Rear Flange"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Rear Flange</span>
                    <Input
                      name="Rear Flange"
                      placeholder="Rear Flange"
                      value={values["Rear Flange"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["Clevis Mount"] && errors["Clevis Mount"]
                        ? errors["Clevis Mount"]
                        : ""
                    }
                    validateStatus={
                      touched["Clevis Mount"] && errors["Clevis Mount"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Clevis Mount</span>
                    <Input
                      name="Clevis Mount"
                      placeholder="Clevis Mount"
                      value={values["Clevis Mount"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["Side Load Adapter"] &&
                        errors["Side Load Adapter"]
                        ? errors["Side Load Adapter"]
                        : ""
                    }
                    validateStatus={
                      touched["Side Load Adapter"] &&
                        errors["Side Load Adapter"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Side Load Adapter</span>
                    <Input
                      name="Side Load Adapter"
                      placeholder="Side Load Adapter"
                      value={values["Side Load Adapter"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["Stop Coller"] && errors["Stop Coller"]
                        ? errors["Stop Coller"]
                        : ""
                    }
                    validateStatus={
                      touched["Stop Coller"] && errors["Stop Coller"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Stop Coller</span>
                    <Input
                      name="Stop Coller"
                      placeholder="Stop Coller"
                      value={values["Stop Coller"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["RATE PER NM"] && errors["RATE PER NM"]
                        ? errors["RATE PER NM"]
                        : ""
                    }
                    validateStatus={
                      touched["RATE PER NM"] && errors["RATE PER NM"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">RATE PER NM</span>
                    <Input
                      name="RATE PER NM"
                      placeholder="RATE PER NM"
                      value={values["RATE PER NM"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["NO CAP"] && errors["NO CAP"]
                        ? errors["NO CAP"]
                        : ""
                    }
                    validateStatus={
                      touched["NO CAP"] && errors["NO CAP"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">NO CAP</span>
                    <Input
                      name="NO CAP"
                      placeholder="NO CAP"
                      value={values["NO CAP"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["METTALIC CAP"] && errors["METTALIC CAP"]
                        ? errors["METTALIC CAP"]
                        : ""
                    }
                    validateStatus={
                      touched["METTALIC CAP"] && errors["METTALIC CAP"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">METTALIC CAP</span>
                    <Input
                      name="METTALIC CAP"
                      placeholder="METTAL IC CAP"
                      value={values["METTALIC CAP"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["METALLIC CAP WITH PU BUTTON"] &&
                        errors["METALLIC CAP WITH PU BUTTON"]
                        ? errors["METALLIC CAP WITH PU BUTTON"]
                        : ""
                    }
                    validateStatus={
                      touched["METALLIC CAP WITH PU BUTTON"] &&
                        errors["METALLIC CAP WITH PU BUTTON"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">
                      METALLIC CAP WITH PU BUTTON{" "}
                    </span>
                    <Input
                      name="METALLIC CAP WITH PU BUTTON"
                      placeholder="METALLIC CAP WITH PU BUTTON"
                      value={values["METALLIC CAP WITH PU BUTTON"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["LOCK NUT EXTRA"] && errors["LOCK NUT EXTRA"]
                        ? errors["LOCK NUT EXTRA"]
                        : ""
                    }
                    validateStatus={
                      touched["LOCK NUT EXTRA"] && errors["LOCK NUT EXTRA"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">LOCK NUT EXTRA</span>
                    <Input
                      name="LOCK N UT EXTRA"
                      placeholder="LOCK NUT EXTRA"
                      value={values["LOCK NUT EXTRA"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                {items.map((item, index) => (
                  <Row gutter={[16, 16]} key={index}>
                    {fileTypes.map((fileType) => (
                      <Col span={6} key={`${item}-${fileType.name}`}>
                        <CustomUpload
                          name={`${item.replace(/\s+/g, "_").toLowerCase()}_${fileType.name
                            }`}
                          fileType={fileType.type}
                          accept={fileType.accept}
                          setFieldValue={setFieldValue}
                          resetTrigger={resetTrigger}
                          fileInputRef={fileInputRef}
                          className="w-full"
                        >
                          <Button
                            icon={<UploadOutlined />}
                            className="w-full md:w-56 h-10"
                            style={{ marginBottom: "16px" }}
                          >
                            Upload {fileType.label}
                          </Button>
                        </CustomUpload>
                      </Col>
                    ))}
                  </Row>
                ))}
              </Row>

              <Row gutter={16} className="mt-8">
                {["rear_flange", "front_flange"].map((category) => {
                  const selectedCategoryFiles = selectedPrices
                    ? Object.entries(selectedPrices).filter(
                      ([key, value]) =>
                        key.startsWith(category) && value !== null
                    )
                    : [];

                  if (selectedCategoryFiles?.length === 0) return null;

                  return (
                    <Col span={12} key={category}>
                      <Card
                        title={category.replace("_", " ").toUpperCase()}
                        bordered={true}
                        style={{ marginBottom: "16px" }}
                      >
                        <Row gutter={[16, 16]}>
                          {selectedCategoryFiles?.map(([key, filePath]) => (
                            <Col span={12} key={key}>
                              <div
                                style={{
                                  marginTop: "8px",
                                  color: "#555",
                                  fontSize: "14px",
                                }}
                              >
                                <div className="flex">
                                  <span className="font-medium">
                                    {key.split("_").pop()}:{" "}
                                  </span>
                                  <span className="ml-1 flex flex-col items-start justify-start gap-2" > 
                                    {filePath && filePath !== "null"
                                      ? filePath.split("/").pop()
                                      : "N/A"}

<div>
                                    <button
                                      onClick={() => handleFileClick(filePath)}
                                      className="text-blue-500  underline mr-2"
                                    >
                                      {/* <FaEye /> */}
view
                                    </button>

                                    <span>
                                      <button
                                        onClick={() => handleFileDownload(filePath)}
                                        className="text-red-500  underline ml-2"
                                      >
                                        {/* <MdDownload /> */}
                                        Download
                                      </button>
                                    </span>
                                  </div>
                                  </span>

                                 
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </Card>
                    </Col>
                  );
                })}
              </Row>


              <Modal
                title="File Viewer"
                visible={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
                centered
                width={800}
              >
                <div
                  className="flex justify-center items-center"
                  style={{ height: "100%" }}
                >
                  {isLoading ? (
                    <div style={{ textAlign: "center" }}>
                      <Skeleton.Image
                        active={true}
                        style={{ width: 400, height: 300 }}
                      />
                    </div>
                  ) : /.pdf$/i.test(modalContent) ? (
                    <iframe
                      src={modalContent}
                      title="PDF Viewer"
                      width="100%"
                      height="500px"
                      style={{
                        border: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                  ) : (
                    <img
                      src={modalContent}
                      alt="Thumbnail"
                      style={{ maxWidth: "100%", maxHeight: "500px" }}
                    />
                  )}
                </div>
              </Modal>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item className="flex justify-end mt-6">
                    <div className="fixed bottom-0 right-0 w-[1000px] p-4 bg-white shadow-lg z-10 flex justify-end items-center">
                      <Button
                        type="primary"
                        className="mr-2"
                        onClick={handlePrevious}
                        disabled={currentIndex === 0}
                      >
                        Previous
                      </Button>
                      <Button
                        type="primary"
                        className="mr-4"
                        onClick={handleNext}
                        disabled={currentIndex >= total - 1}
                      >
                        Next
                      </Button>
                      <Button
                        onClick={handleCancel}
                        type="primary"
                        className="mr-2"
                      >
                        Cancel
                      </Button>
                      <div className="flex items-center">
                        {!edit && (
                          <Button
                            type="primary"
                            className="!p-2"
                            onClick={() => handleSaveAndClose(submitForm)}
                          >
                            Save and Close
                          </Button>
                        )}
                        {edit && (
                          <Button
                            type="primary"
                            htmlType="submit"
                            onClick={handleCancel}
                          >
                            Update
                          </Button>
                        )}
                      </div>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </FormikForm>
          )}
        </Formik>
      </div>
    </>
  );
};

export default PriceForm;
