import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import ShockAbsorberPrice from "../../view/Shock-Absorber/Prices";
import CraneBufferPrice from "../../view/Crane-Buffer/Prices";
import ShockAbsorberInquiry from "../../view/Shock-Absorber/Inquires/index";
import CraneBufferInquiry from "../../view/Crane-Buffer/Inquires/index";
import ShockAbsorberInquiryDetail from "../../view/Shock-Absorber/Inquires/components/InquiryDetails";
import CraneBufferInquiryDetail from "../../view/Crane-Buffer/Inquires/components/InquiryDetails";
import CraneBufferCatalog from "../../view/Crane-Buffer/Catalog";
import ShockAbsorberCatalog from "../../view/Shock-Absorber/Catalog";
import Dashboard from "../../view/Dasboard/components/Dashboard";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />}></Route>
      <Route
        path="/shockabsorber/prices"
        element={<ShockAbsorberPrice />}
      ></Route>

      <Route path="/cranebuffer/prices" element={<CraneBufferPrice />}></Route>
      <Route
        path="/shockabsorber/inquiry"
        element={<ShockAbsorberInquiry />}
      ></Route>
      <Route
        path="/cranebuffer/inquiry"
        element={<CraneBufferInquiry />}
      ></Route>
      <Route
        path="/shockabsorber/inquiry/detail"
        element={<ShockAbsorberInquiryDetail />}
      ></Route>
      <Route
        path="/cranebuffer/inquiry/detail"
        element={<CraneBufferInquiryDetail />}
      ></Route>

      <Route
        path="/cranebuffer/catalog"
        element={<CraneBufferCatalog />}
      ></Route>

      <Route
        path="/shockabsorber/catalog"
        element={<ShockAbsorberCatalog />}
      ></Route>

      <Route path="/*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default AppRoutes;
