import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  apiGetAllInquiry,
  apiGetAllInquiryDetails,
} from "../../../../services/InquiryService";

export const getInquiry = createAsyncThunk(
  "inquiry/data/get",
  async ({ data, headers }) => {
    const response = await apiGetAllInquiry(data, headers);
    return response.data;
  }
);

export const getInquiryDetails = createAsyncThunk(
  "inquiry/data/getDetails",
  async ({ data, headers }) => {
    const response = await apiGetAllInquiryDetails(data, headers);
    return response.data;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  productStatus: 0,
};

const dataSlice = createSlice({
  name: "inquiry/data",
  initialState: {
    loading: false,
    inquiryList: [],
    inquiryDetailsList: [],
    tableData: initialTableData,
    filterData: initialFilterData,
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInquiry.fulfilled, (state, action) => {
        state.loading = false;
        state.inquiryList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getInquiry.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getInquiry.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getInquiryDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.inquiryDetailsList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getInquiryDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getInquiryDetails.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { setTableData, setFilterData } = dataSlice.actions;

export default dataSlice.reducer;
