import React, { useEffect, useState } from "react";
import { Formik, Field, Form as FormikForm } from "formik";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Switch,
  Select,
  notification,
} from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import { createData, getPrice, putData } from "../store/dataSlice";
import { DatePicker } from "antd";

const PriceForm = () => {
  const dispatch = useDispatch();
  const { Option } = Select;

  const [edit, setEdit] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.shockPrice.data.tableData
  );

  // const filterOption = (input, option) =>
  //   (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const selectedPrices = useSelector(
    (state) => state.shockPrice.state.selectedPrices
  );


  const validationSchema = Yup.object({
    Model: Yup.string().required("Required"),
    NEWPRICE: Yup.string().required("Required"),
  });

  const handleCancel = () => {
    dispatch(toggleNewDialog(false));
  };

  useEffect(() => {
    if (selectedPrices) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [selectedPrices]);

  const initialValues = {
    Model: selectedPrices?.Model || "",
    NEWPRICE: selectedPrices?.NEWPRICE || 0,
    Stroke: selectedPrices?.Stroke || 0,
    "SERIAL NO": selectedPrices?.["SERIAL NO"] || 0,
    THREADING: selectedPrices?.THREADING || "",
    nmperstroke: selectedPrices?.nmperstroke || 0,
    nmperhr: selectedPrices?.nmperhr || 0,
    EMASSMIN: selectedPrices?.EMASSMIN || 0,
    EMASSMAX: selectedPrices?.EMASSMAX || 0,
    SFMIN: selectedPrices?.SFMIN || 0,
    SFMAX: selectedPrices?.SFMAX || 0,
    "WEIGHT IN GMS": selectedPrices?.["WEIGHT IN GMS"] || 0,
    "Front Flange": selectedPrices?.["Front Flange"] || 0,
    "Clevis Mount": selectedPrices?.["Clevis Mount"] || 0,
    "Side Load Adapter": selectedPrices?.["Side Load Adapter"] || "",
    "Stop Coller": selectedPrices?.["Stop Coller"] || 0,
    "RATE PER NM": selectedPrices?.["RATE PER NM"] || 0,
    "NO CAP": selectedPrices?.["NO CAP"] || 0,
    "METTALIC CAP": selectedPrices?.["METTALIC CAP"] || 0,
    "METALLIC CAP WITH PU BUTTON":
      selectedPrices?.["METALLIC CAP WITH PU BUTTON"] || 0,
    "LOCK NUT EXTRA": selectedPrices?.["LOCK NUT EXTRA"] || 0,
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const action = edit
        ? await dispatch(
            putData({
              data: {
                id: selectedPrices?._id,
                ...values,
              },
              headers: {
                connection: "shockabsorber",
              },
            })
          )
        : await dispatch(
            createData({
              data: {
                values,
              },
              headers: {
                connection: "shockabsorber",
              },
            })
          );

      if (action.payload.code === 200) {
        dispatch(
          getPrice({
            data: {
              pageIndex,
              pageSize,
            },
            headers: {
              connection: "shockabsorber",
            },
          })
        );

        api.success({
          message: "Form Submitted Successfully.",
        });
      } else {
        console.error(
          "Error occurred during form submission:",
          action.payload.error
        );
      }
      return action.payload.code;
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSaveAndClose = async (submitForm) => {
    submitForm().then((actionCode) => {
      if (actionCode === 200) {
        handleCancel();
      }
    });
  };
  return (
    <>
      <div className="">
        {contextHolder}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            submitForm,
          }) => (
            <FormikForm>
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.Model && errors.Model ? errors.Model : ""}
                    validateStatus={
                      touched.Model && errors.Model ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Model</span>
                    <Input
                      name="Model"
                      placeholder="Model"
                      value={values.Model}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.NEWPRICE && errors.NEWPRICE ? errors.NEWPRICE : ""
                    }
                    validateStatus={
                      touched.NEWPRICE && errors.NEWPRICE ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Price</span>
                    <Input
                      name="NEWPRICE"
                      placeholder="Price"
                      value={values.NEWPRICE}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["SERIAL NO"] && errors["SERIAL NO"]
                        ? errors["SERIAL NO"]
                        : ""
                    }
                    validateStatus={
                      touched["SERIAL NO"] && errors["SERIAL NO"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">SERIAL NO</span>
                    <Input
                      name="SERIAL NO"
                      placeholder="SERIAL NO"
                      value={values["SERIAL NO"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.Stroke && errors.Stroke ? errors.Stroke : ""}
                    validateStatus={
                      touched.Stroke && errors.Stroke ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Stroke</span>
                    <Input
                      name="Stroke"
                      placeholder="Stroke"
                      value={values.Stroke}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.THREADING && errors.THREADING
                        ? errors.THREADING
                        : ""
                    }
                    validateStatus={
                      touched.THREADING && errors.THREADING
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">THREADING</span>
                    <Input
                      name="THREADING"
                      placeholder="THREADING"
                      value={values.THREADING}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.nmperstroke && errors.nmperstroke
                        ? errors.nmperstroke
                        : ""
                    }
                    validateStatus={
                      touched.nmperstroke && errors.nmperstroke
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">nmperstroke</span>
                    <Input
                      name="nmperstroke"
                      placeholder="nmperstroke"
                      value={values.nmperstroke}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.nmperhr && errors.nmperhr ? errors.nmperhr : ""
                    }
                    validateStatus={
                      touched.nmperhr && errors.nmperhr ? "error" : undefined
                    }
                  >
                    <span className="text-xs">nmperhr</span>
                    <Input
                      name="nmperhr"
                      placeholder="nmperhr"
                      value={values.nmperhr}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.EMASSMIN && errors.EMASSMIN ? errors.EMASSMIN : ""
                    }
                    validateStatus={
                      touched.EMASSMIN && errors.EMASSMIN ? "error" : undefined
                    }
                  >
                    <span className="text-xs">EMASSMIN</span>
                    <Input
                      name="EMASSMIN"
                      placeholder="EMASSMIN"
                      value={values.EMASSMIN}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.EMASSMAX && errors.EMASSMAX ? errors.EMASSMAX : ""
                    }
                    validateStatus={
                      touched.EMASSMAX && errors.EMASSMAX ? "error" : undefined
                    }
                  >
                    <span className="text-xs">EMASSMAX</span>
                    <Input
                      name="EMASSMAX"
                      placeholder="EMASSMAX"
                      value={values.EMASSMAX}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.SFMIN && errors.SFMIN ? errors.SFMIN : ""}
                    validateStatus={
                      touched.SFMIN && errors.SFMIN ? "error" : undefined
                    }
                  >
                    <span className="text-xs">SFMIN</span>
                    <Input
                      name="SFMIN"
                      placeholder="SFMIN"
                      value={values.SFMIN}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.SFMAX && errors.SFMAX ? errors.SFMAX : ""}
                    validateStatus={
                      touched.SFMAX && errors.SFMAX ? "error" : undefined
                    }
                  >
                    <span className="text-xs">SFMAX</span>
                    <Input
                      name="SFMAX"
                      placeholder="SFMAX"
                      value={values.SFMAX}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["WEIGHT IN GMS"] && errors["WEIGHT IN GMS"]
                        ? errors["WEIGHT IN GMS"]
                        : ""
                    }
                    validateStatus={
                      touched["WEIGHT IN GMS"] && errors["WEIGHT IN GMS"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">WEIGHT IN GMS</span>
                    <Input
                      name="WEIGHT IN GMS"
                      placeholder="WEIGHT IN GMS"
                      value={values["WEIGHT IN GMS"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["Front Flange"] && errors["Front Flange"]
                        ? errors["Front Flange"]
                        : ""
                    }
                    validateStatus={
                      touched["Front Flange"] && errors["Front Flange"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Front Flange</span>
                    <Input
                      name="Front Flange"
                      placeholder="Front Flange"
                      value={values["Front Flange"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["Clevis Mount"] && errors["Clevis Mount"]
                        ? errors["Clevis Mount"]
                        : ""
                    }
                    validateStatus={
                      touched["Clevis Mount"] && errors["Clevis Mount"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Clevis Mount</span>
                    <Input
                      name="Clevis Mount"
                      placeholder="Clevis Mount"
                      value={values["Clevis Mount"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["Side Load Adapter"] &&
                      errors["Side Load Adapter"]
                        ? errors["Side Load Adapter"]
                        : ""
                    }
                    validateStatus={
                      touched["Side Load Adapter"] &&
                      errors["Side Load Adapter"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Side Load Adapter</span>
                    <Input
                      name="Side Load Adapter"
                      placeholder="Side Load Adapter"
                      value={values["Side Load Adapter"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["Stop Coller"] && errors["Stop Coller"]
                        ? errors["Stop Coller"]
                        : ""
                    }
                    validateStatus={
                      touched["Stop Coller"] && errors["Stop Coller"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Stop Coller</span>
                    <Input
                      name="Stop Coller"
                      placeholder="Stop Coller"
                      value={values["Stop Coller"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["RATE PER NM"] && errors["RATE PER NM"]
                        ? errors["RATE PER NM"]
                        : ""
                    }
                    validateStatus={
                      touched["RATE PER NM"] && errors["RATE PER NM"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">RATE PER NM</span>
                    <Input
                      name="RATE PER NM"
                      placeholder="RATE PER NM"
                      value={values["RATE PER NM"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["NO CAP"] && errors["NO CAP"]
                        ? errors["NO CAP"]
                        : ""
                    }
                    validateStatus={
                      touched["NO CAP"] && errors["NO CAP"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">NO CAP</span>
                    <Input
                      name="NO CAP"
                      placeholder="NO CAP"
                      value={values["NO CAP"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["METTALIC CAP"] && errors["METTALIC CAP"]
                        ? errors["METTALIC CAP"]
                        : ""
                    }
                    validateStatus={
                      touched["METTALIC CAP"] && errors["METTALIC CAP"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">METTALIC CAP</span>
                    <Input
                      name="METTALIC CAP"
                      placeholder="METTAL IC CAP"
                      value={values["METTALIC CAP"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["METALLIC CAP WITH PU BUTTON"] &&
                      errors["METALLIC CAP WITH PU BUTTON"]
                        ? errors["METALLIC CAP WITH PU BUTTON"]
                        : ""
                    }
                    validateStatus={
                      touched["METALLIC CAP WITH PU BUTTON"] &&
                      errors["METALLIC CAP WITH PU BUTTON"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">
                      METALLIC CAP WITH PU BUTTON{" "}
                    </span>
                    <Input
                      name="METALLIC CAP WITH PU BUTTON"
                      placeholder="METALLIC CAP WITH PU BUTTON"
                      value={values["METALLIC CAP WITH PU BUTTON"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched["LOCK NUT EXTRA"] && errors["LOCK NUT EXTRA"]
                        ? errors["LOCK NUT EXTRA"]
                        : ""
                    }
                    validateStatus={
                      touched["LOCK NUT EXTRA"] && errors["LOCK NUT EXTRA"]
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">LOCK NUT EXTRA</span>
                    <Input
                      name="LOCK N UT EXTRA"
                      placeholder="LOCK NUT EXTRA"
                      value={values["LOCK NUT EXTRA"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item className="flex justify-end">
                    <div className="flex relative">
                      <Button
                        onClick={handleCancel}
                        type="primary"
                        className="mr-4"
                      >
                        Cancel
                      </Button>
                      <div
                        className="flex items-center"
                        // onMouseEnter={() => setShowSaveNext(true)}
                        // onMouseLeave={() => setShowSaveNext(false)}
                      >
                        {!edit && (
                          <Button
                            type="primary"
                            className="!p-2"
                            onClick={() => handleSaveAndClose(submitForm)}
                          >
                            Save and Close
                          </Button>
                        )}
                      </div>
                      {edit && (
                        <Button
                          type="primary"
                          htmlType="submit"
                          onClick={handleCancel}
                        >
                          Update{" "}
                        </Button>
                      )}
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </FormikForm>
          )}
        </Formik>
      </div>
    </>
  );
};

export default PriceForm;
